.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.question-title {
  color: #2bc550;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.question-body {
  color: #333;
  font-size: 16px;
  
}

.question-item-bullet {
  font-size: 14px;
  margin-right: .5em;
}

.question-item {
  margin-right: 20px;
}

.color-primary {
  color: #2bc550;
}