body {
  margin: 0;
  /*font-family: "Arial", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 16px;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.landing-background {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ), url('/public/assets/landing.jpg');
  height: 400px;
}

.site-navbar {
  margin-bottom: 0;
  z-index: 1999;
  /*position: absolute;
  top: 0;*/
  width: 100%;
  padding: 0rem;
  background: #222;
}

.bg-dark {
  background-color: #222!important;
}

@media(max-width: 991.98px) {
  .site-navbar {
      padding-top:3rem;
      padding-bottom: 3rem
  }
}

@media(max-width: 768px) {
  .site-navbar {
      padding-top:0rem;
      padding-bottom: 0rem
  }
}

.site-navbar .site-logo {
  margin: 0;
  padding: 0;
  font-size: 1.7rem
}

.site-navbar .site-logo a {
  text-transform: uppercase;
  color: #fff
}

@media(max-width: 991.98px) {
  .site-navbar .site-logo {
      float:left;
      position: relative
  }
}

.site-logo {
  width: 40px;
  height: 40px;
  float: left;
}

.site-section {
  padding: 2.5em 0
}

.site-section.first {
  padding: 0.5em 0
}

@media(max-width: 991.98px) {
  .site-section {
    padding:5em 0
  }

  .site-section.first {
    padding:0.5em 0
  }
  
}

.site-section.site-section-sm {
  padding: 1em 0
}

.site-footer {
  padding: 0em 0;
  background: #222
}

@media(min-width: 768px) {
  .site-footer {
      padding: 0 0
  }
}

.site-footer .border-top {
  border-top: 1px solid rgba(255,255,255,.1)!important
}

.site-footer p {
  color: #fff
}

.site-footer h2,.site-footer h3,.site-footer h4,.site-footer h5 {
  color: #fff
}

.site-footer a {
  color: rgba(255,255,255,.5)
}

.site-footer a:hover {
  color: #fff
}

.site-footer ul li {
  margin-bottom: 10px
}

.site-footer .footer-heading {
  font-size: 16px;
  color: #fff
}

.site-section-cover {
  /*position: relative;*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center
}

.site-section-cover,.site-section-cover .container {
  position: relative;
  z-index: 2
}

.site-section-cover,.site-section-cover .container>.row {
  height: calc(100vh - 196px);
  min-height: 400px
}

@media(max-width: 991.98px) {
  .site-section-cover,.site-section-cover .container>.row {
      height:calc(70vh - 196px);
      min-height: 400px
  }
}

.site-section-cover.overlay {
  position: relative
}

.site-section-cover.overlay:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.3);
  z-index: 1
}

.site-section-cover.inner-page,.site-section-cover.inner-page .container>.row {
  height: auto;
  min-height: auto;
  padding: 2em 0
}

.site-section-cover.img-bg-section {
  background-size: cover;
  background-repeat: no-repeat
}

.site-section-cover h1 {
  color: #fff;
}

@media(max-width: 991.98px) {
  .site-section-cover h1 {
      font-size:2rem
  }
}

.site-section-cover p {
  font-size: 18px;
  color: #fff
}

.site-section {
  padding: 2rem 0
}

@media(max-width: 991.98px) {
  .site-section {
      padding:1rem 0
  }
}

.pt-aftermenu {
  padding-top: 8em;
}

.color-white {
  color: white;
}

.color-black {
  color: black;
}

.text-layer {
  background-color: rgba(0, 0, 0, 0.3);
}

.wrapped-image {
  float: left; margin: 4px 10px 0px 0px; border: 1px solid #000000;
}

.inversion-label {
  font-size: 1em;
  line-height: 1.6em;
}

.inversion-value-normal {
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.6em;
  text-align: right;
}

.inversion-value-highlighted {
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1.6em;
  text-align: right;
  width: 200px;
}

.divider-1 {
  border-bottom: 1px solid #c0c0c0;
}

/* Charts */

.bar-group .name-label {
  text-anchor: end;
  font-weight: 400;
  font-size: 0.8em;
  fill: #333;
}

.bar-group .value-label {
  text-anchor: start;
  fill: #333;
  font-weight: bold;
  font-size: 0.8em;
}

.bar-group:hover rect {
  opacity: 0.5 !important;
}

.bar-group:hover .name-label {
  font-weight: 400;
}

.carousel-image {
  height: 400px;
}

@media(max-width: 991.98px) {
  .carousel-image {
    height: 200px;
  }
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
  text-align: center;
  z-index: 20;
}

#progressbar .active {
  color: #000000;
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 25%;
  float: left;
  position: relative;
}

#progressbar #account:before {
  font-family: FontAwesome;
  content: "\f023";
  text-align: center;
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "\f007";
  text-align: center;
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: "\f09d";
  text-align: center;
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "\f00c";
  text-align: center;
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 8px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: 10
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: skyblue
}

.wizzard-steps {
  text-align: center;
}

.wizzard-steps .step {
  text-align: center;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
  width: 20%;
}

.wizzard-steps .step .icon {
  text-align: center;
  width: 60px;
  height: 60px;
  line-height: 45px;
  color: #ffffff;
  background: lightgray;
  border-color: #333;
  border-width: 4px;
  border-radius: 50%;
  margin: 0 auto 0px auto;
  padding-top: 14px;
  display: block;
}

@media(max-width: 768px) {
  .wizzard-steps .step .icon {
    width: 40px;
    height: 40px;
    line-height: 35px;
    padding-top: 4px;
  }
}

.wizzard-steps .title {
  text-align: center;
  display: block;
  font-weight: bold;
  font-size: 20px;
  color: #09d3ac;
  display: none;
}

.wizzard-steps .step .title {
  text-align: center;
  display: block;
  font-weight: bold;
  font-size: 20px;
}

@media(max-width: 768px) {
  .wizzard-steps .step .title {
    display: none;
  }
  .wizzard-steps .title {
    display: block;
  }
}

.wizzard-steps .step .icon i {
  line-height: 32px;
  font-size: 32px;
}

@media(max-width: 768px) {
  .wizzard-steps .step .icon i {
    line-height: 20px;
    font-size: 20px;
  }
}

.wizzard-steps .step .icon.active {
  background: #09d3ac;
}

.wizzard-steps .step .title.active {
  color: #09d3ac;
}
